import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Page from "./../components/Layout/Page";
import Container from "../components/Layout/Container";
// markup
const IndexPage = () => {
  return (
    <Page className="home">
      <Container>
        <figure className="largeLogo">
          <StaticImage
            src="./../images/white-walls-interior-design-aizawl-mizoram.png"
            alt="White Wall, Interior Designer in Aizawl Mizoram"
            layout="fullWidth"
            placeholder="none"
          />
        </figure>
      </Container>
    </Page>
  );
};

export default IndexPage;
